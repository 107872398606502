import { Controller } from "@hotwired/stimulus"
import * as Turbo from "@hotwired/turbo"

export default class extends Controller {
  static targets = ["dest"]

  change() {
    const url = this.destTarget.value;
    Turbo.visit(url);
  }
}
