import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["eth_message", "eth_address", "eth_signature", "submitButton", "form"]

  initialize() {

    // Disable login button and show warning if no wallet detected
    if (typeof window.ethereum !== 'undefined') {
      console.log('web3 detected');
      this.submitButtonTarget.disabled = false;
    } else {
      console.warn('web3 NOT detected');
      this.submitButtonTarget.classList.remove('hover:cursor-pointer');
      this.submitButtonTarget.classList.remove('hover:bg-gray-200');
      this.submitButtonTarget.classList.remove('bg-gray-50');
      this.submitButtonTarget.classList.add('bg-gray-200');
      document.getElementById('web-warning').classList.remove('hidden');
    }

  }

  async signMessage(event) {
    event.preventDefault();

    const accounts = await requestAccounts();
    const address = accounts[0];
    const domain = 'frenscan.com'
    const statement = '';  // optional
    const uri = 'https://frenscan.com';
    const version = '1'; // MUST be 1
    const chain_id = 1; // default to mainnet
    const nonce = await getNonceByAddress(address);
    const issued_at = (new Date()).toISOString();
    //const expiration_time =

    // sign a message with current time
    //const requestTime = Math.floor(new Date().getTime() / 1000);
    //const eip4361 = "Sign in with your Ethereum account " + requestTime;

    const eip4361 =
      `${domain} wants you to sign in with your Ethereum account:
  ${address}

  URI: ${uri}
  Version: ${version}
  Chain ID: ${chain_id}
  Nonce: ${nonce}
  Issued At: ${issued_at}`;

    // Optional fields not included:
    //${statement}
    //Expiration Time: ${expiration_time}
    //Not Before: ${not-before}
    //Request ID: ${request-id}
    //Resources:
    //- ${resources[0]}
    //- ${resources[1]}
    //...
    //- ${resources[n]}

    const signature = await personalSign(address, eip4361);

    // populate and submit form
    if (signature) {
      this.eth_messageTarget.value = eip4361;
      this.eth_addressTarget.value = address;
      this.eth_signatureTarget.value = signature;
      this.formTarget.submit();
    } else {
      // TODO: Add "user canceled' message
      buttonEthConnect.disabled = false;
    }
  }
}


// request ethereum wallet access and approved accounts[]
async function requestAccounts() {
  const accounts = await ethereum.request({ method: 'eth_requestAccounts' });
  return accounts;
}

// request ethereum signature for message from account
async function personalSign(account, message) {
  try {
    const signature = await ethereum.request({ method: 'personal_sign', params: [ message, account ] });
    return signature;
  }  catch (exception) {
    console.log('EXCEPTION: ', exception);
  }
}

// get nonce from backend
async function getNonceByAddress(address) {
  const csrfToken = document.querySelector("[name='csrf-token']").content
  const response = await fetch(`/users/${address}/nonce`, {
    method: 'POST',
    headers: {
      "X-CSRF-Token": csrfToken,
      "Content-Type": "application/json"
    }
  });
  const nonceJson = await response.json();
  if (!nonceJson) return null;
  const nonce = nonceJson[0].eth_nonce;
  return nonce;
}
