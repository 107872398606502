import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['toggleable']
  static values =  {
    currentstate: Boolean,
    updateUrl: String,
    modelName: String,
  }

  toggle() {
    if (!this.updateUrlValue) { return; }
    this.currentstateValue = !this.currentstateValue;
    this.updateStyles()
    if (this.updateUrlValue) {
      this.updateModel()
    }
  }

  connect() {
    this.toggleableTargets.map(t => {
      if (this.currentstateValue) {
        this.classesToggle(t, t.dataset.onClass)
      } else {
        this.classesToggle(t, t.dataset.offClass)
      }
    })
    this.token = document.querySelector(
      'meta[name="csrf-token"]'
    ).content;
  }

  updateStyles() {
    this.toggleableTargets.map(t => this.classesToggle(t, t.dataset.onClass, t.dataset.offClass))
  }

  classesToggle(el, ...args) {
    args.map(cls => cls.split(' ').map(e => el.classList.toggle(e)))
  }

  async updateModel() {
    let formData = new FormData()
    formData.append(`${this.modelNameValue}[enabled]`, this.currentstateValue);

    let res = await fetch(this.updateUrlValue, {
      body: formData,
      method: 'PATCH',
      credentials: "include",
      dataType: "script",
      headers: {
        'X-Requested-With': 'XMLHttpRequest',
        "X-CSRF-Token": this.token,
      },
    })

    if (res.status != 204) {
      this.currentstateValue = !this.currentstateValue
      this.updateStyles()
    }
  }
}
