import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["template", "target"]
  static values = {
    wrapperSelector: {
      type: String,
      default: '.condition-wrapper'
    }
  }

  connect() {
    // TODO: dedupe with below
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  addCondition(e) {
    e.preventDefault();
    const content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeCondition(e) {
    e.preventDefault();
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    wrapper.remove();
  }
}
