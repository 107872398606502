import { Controller } from "@hotwired/stimulus";
import {computePosition} from '@floating-ui/dom';

// Connects to data-controller="popper"
export default class extends Controller {
  static targets = ["element", "tooltip"];
  // static values = {
  //   placement: { type: String, default: "right" },
  //   offset: { type: Array, default: [0, 8] },
  // };

  connect() {
    computePosition(this.elementTarget, this.tooltipTarget).then(({x, y}) => {
      Object.assign(this.tooltipTarget.style, {
        left: `75px`,
        top: `${y-30}px`,
      });
    });
  }

  show(event) {
    this.tooltipTarget.classList.remove("invisible")
    this.tooltipTarget.classList.add("visible")

    // We need to tell Popper to update the tooltip position
    // after we show the tooltip, otherwise it will be incorrect
    // this.popperInstance.update();
  }

  hide(event) {
     this.tooltipTarget.classList.remove("visible")
     this.tooltipTarget.classList.add("invisible")
  }

  // Destroy the Popper instance
  // disconnect() {
  // }
}
