var jQuery = require("jquery")
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;

var jazzicon = require('@metamask/jazzicon')

// TODO: Get rid of jquery
// with document.addEventListener..  etc
$(document).on('turbo:load', function() {
  $('.jazzicon').each(function(i, e) {
    var addr = $(e).data('address');
    var size = $(e).data('size');
    var icon = jazzicon(parseInt(size), addrToInt(addr));

    if ($(e).html() == '') {
      $(e).append(icon);
    }
  });
});

function addrToInt(addr) {
  var a = addr.slice(2,10);
  var n = parseInt(a, 16);
  return n;
}
