import { Controller } from "@hotwired/stimulus"

// Sets a select with options for contact functions
export default class extends Controller {
  static targets = ["target"]

  onChange(e) {
    const template = document.getElementById(`e-${e.target.value}`);
    if (template) {
      this.targetTarget.innerHTML = template.innerHTML;
    }
  }
}
