import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["newConditionType", "target", "callTemplate", "eventTemplate", "transactionTemplate"]
  static values = {
    wrapperSelector: {
      type: String,
      default: '.condition-wrapper'
    }
  }

  addCondition(e) {
    e.preventDefault();
    const content = this[this.newConditionTypeTarget.value + 'Target'].innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    this.targetTarget.insertAdjacentHTML('beforebegin', content)
  }

  removeCondition(e) {
    e.preventDefault();
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    wrapper.remove();
  }
}
