// Entry point for the build script in your package.json

require("@rails/activestorage").start()

//require("./channels")
require("./jazzicon")

import { Application } from "@hotwired/stimulus"
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"
import * as Turbo from "@hotwired/turbo"
import { Alert, Dropdown, Modal, Tabs, Popover, Toggle } from "tailwindcss-stimulus-components"
import Clipboard from 'stimulus-clipboard'
import "chartkick/chart.js"

window.Stimulus = Application.start()
const context = require.context("./controllers", true, /\.js$/)
Stimulus.load(definitionsFromContext(context))

// Import and register all TailwindCSS Components
window.Stimulus.register('alert', Alert)
// window.Stimulus.register('autosave', Autosave)
window.Stimulus.register('dropdown', Dropdown)
window.Stimulus.register('modal', Modal)
window.Stimulus.register('tabs', Tabs)
window.Stimulus.register('popover', Popover)
window.Stimulus.register('toggle', Toggle)
// window.Stimulus.register('slideover', Slideover)
window.Stimulus.register('clipboard', Clipboard)
